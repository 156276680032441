import {BillingAddressTitle} from '../BillingAddressTitle/BillingAddressTitle';
import {BillingDetailsForm} from '../BillingDetailsForm/BillingDetailsForm';
import React from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {classes} from './BillingDetails.st.css';
import {MemberDetails} from '../../../../MemberDetails/MemberDetails';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {BillingSameAsShippingCheckbox} from '../BillingSameAsShippingCheckbox';
import {useBillingData} from '../WithBillingData/WithBillingData';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
}

export const BillingDetails = () => {
  const {
    checkoutStore: {isShippingFlow},
  } = useControllerProps();
  const {isShippingValidForBilling, billingSameAsShipping} = useBillingData();
  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      <BillingAddressTitle />
      {isShippingFlow && isShippingValidForBilling && <BillingSameAsShippingCheckbox />}
      {(!isShippingFlow || !billingSameAsShipping || !isShippingValidForBilling) && (
        <MemberOrVisitorBillingDetailsForm />
      )}
    </div>
  );
};

const MemberOrVisitorBillingDetailsForm = () => {
  const {
    memberStore: {isMember, isAddressesAppInstalled},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  if (isMember && isAddressesAppInstalled) {
    return (
      <div className={classes.memberDetails}>
        <MemberDetails
          dropdownLabel={localeKeys.chooseAddressModal.billing_address_title()}
          Form={() => <BillingDetailsForm />}
        />
      </div>
    );
  }
  return <BillingDetailsForm />;
};
