import React from 'react';
import {classes, st} from './Step.st.css';
import {Divider} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepState} from '../../../../../types/app.types';

export interface DynamicStepComponentProps {
  index: number;
}

export enum DynamicStepDataHooks {
  divider = 'DynamicStepDataHooks.divider',
}

export interface DynamicStepProps {
  index: number;
  dataHook: string;
  component: (props: DynamicStepComponentProps) => JSX.Element;
  className?: string;
}

export const DynamicStep = ({index, dataHook, component: Component, className}: DynamicStepProps) => {
  const {
    stepsManagerStore: {stepsList},
  } = useControllerProps();

  const stepState = stepsList[index].state;
  const isLastStep = index === stepsList.length - 1;

  const shouldShowDivider = stepState !== StepState.OPEN && !isLastStep;

  return (
    <div data-hook={dataHook} className={st(classes.stepContainer, className)}>
      <Component index={index} />
      {shouldShowDivider && (
        <Divider className={classes.stepDivider} data-hook={DynamicStepDataHooks.divider} direction="horizontal" />
      )}
    </div>
  );
};
