import * as React from 'react';
import {SectionNotification, ButtonSize, ButtonPriority} from 'wix-ui-tpa/cssVars';
import Error from 'wix-ui-icons-common/on-stage/Error';
import {LineItemDataHooks} from '../LineItem';

export interface OutOfStockWithAButtonProps {
  message: string;
  removeButtonText: string;
  removeButtonOnClick: () => void;
}

export class ItemOutOfStock extends React.PureComponent<OutOfStockWithAButtonProps> {
  public render() {
    const {message, removeButtonText, removeButtonOnClick} = this.props;

    return (
      <SectionNotification type="error" data-hook={LineItemDataHooks.OutOfStock}>
        <SectionNotification.Icon icon={<Error />} />
        <SectionNotification.Text>{message}</SectionNotification.Text>
        <SectionNotification.Button
          key="primary"
          size={ButtonSize.tiny}
          priority={ButtonPriority.basic}
          onClick={removeButtonOnClick}>
          {removeButtonText}
        </SectionNotification.Button>
      </SectionNotification>
    );
  }
}
