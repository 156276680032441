import React, {ComponentType, createContext, useContext, useEffect, useState} from 'react';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {getContactDetailsFromContactFormValues} from '../../../../Form/ContactForm/contactForm.utils';
import {getAddressFromAddressFormValues} from '../../../../Form/AddressForm/addressForm.utils';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';
import {FormInstanceData, useFormInstance} from '../../../../Form/useFormInstance';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {ADD_NEW_ADDRESS_ID, SPECS} from '../../../../constants';
import {updateMandatoryFields} from '../../../../../../domain/utils/cashier.utils';
import {usePaymentsApi} from '../../../../WithPaymentsApi/WithPaymentsApi';
import {ContactModel} from '../../../../../../domain/models/Contact.model';
import {
  isAddressModelEmpty,
  isBillingEqualToShipping,
  isContactModelEmpty,
} from '../../../../../../domain/utils/billingDetails.utils';
import {ApiAddressFragment, FullAddressContactDetailsFragment} from '../../../../../../gql/graphql';
import {useFunctionResultObservation} from '@wix/function-result-observation';
import {StepId} from '../../../../../../types/app.types';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {getBillingFormInitialState, getVatFromVatFormValues} from '../../../../Form/BillingForm/BillingForm.utils';
import {FullNameValue} from '@wix/form-fields';
import {AddressModel} from '../../../../../../domain/models/Address.model';

interface BillingFormData {
  billing: FormInstanceData;
  isFormValid: () => Promise<boolean>;
  initForm: (billingInfo?: AddressWithContactModel) => void;
  updateContactCountry: (country: string) => void;
  getDataForSubmit: (overridePhone?: boolean) => {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
  };
  contactCountry?: string;
}

export type BillingDataContextType = {
  billingFormData: BillingFormData;
  setBillingSameAsShipping: React.Dispatch<React.SetStateAction<boolean>>;
  billingSameAsShipping: boolean;
  isShippingValidForBilling: boolean;
};

export const BillingDataContext = createContext({} as BillingDataContextType);

export function withBillingData<T extends object>(Component: ComponentType<T>) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return function Wrapper(props: T) {
    const {
      paymentStore: {setCashierMandatoryFields, shouldRequirePayment},
      formsStore,
      stepsManagerStore: {activeStep},
      checkoutStore: {checkout, isShippingFlow},
    } = useControllerProps();
    const {experiments} = useExperiments();
    const {paymentsApi} = usePaymentsApi();
    const billingFormData = useBillingFormData();

    const {withObservation} = useFunctionResultObservation();
    const validateShippingAddressForBilling = withObservation(formsStore, 'validateShippingAddressForBilling');

    const {selectedAddressesService, editMode, selectedAddressesServiceId} = useMemberDetailsData();
    const [isShippingValidForBilling, setIsShippingValidForBilling] = useState<boolean>(true);

    const calculateBillingSameAsShippingState = (): boolean => {
      return (
        isShippingFlow &&
        (!checkout.billingInfo || isBillingEqualToShipping(checkout.billingInfo, checkout.shippingDestination))
      );
    };

    const [billingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(calculateBillingSameAsShippingState);

    useEffect(
      () => {
        if (
          (activeStep.stepId !== StepId.payment && activeStep.stepId !== StepId.paymentAndPlaceOrder) ||
          !checkout.shippingDestination
        ) {
          return;
        }

        async function validate() {
          if (shouldRequirePayment) {
            await updateMandatoryFields(
              paymentsApi,
              setCashierMandatoryFields,
              checkout.shippingDestination?.address?.country
            );
          }

          void validateShippingAddressForBilling(checkout.shippingDestination!).then((value) => {
            setIsShippingValidForBilling(value);
          });
        }

        void validate();
      },
      /* eslint-disable react-hooks/exhaustive-deps */ [activeStep.stepId]
    );

    const withCheckoutHackForReact18Tests = experiments.enabled(SPECS.WithCheckoutHackForReact18Tests);

    useEffect(
      () => {
        if (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) {
          return billingFormData.initForm(new AddressWithContactModel({addressesServiceId: ADD_NEW_ADDRESS_ID}));
        } else if (
          (withCheckoutHackForReact18Tests && selectedAddressesServiceId) ||
          (!withCheckoutHackForReact18Tests && /* istanbul ignore next */ editMode)
        ) {
          billingFormData.initForm(selectedAddressesService);
        }
      },
      /* eslint-disable react-hooks/exhaustive-deps */ [editMode, selectedAddressesServiceId]
    );

    useEffect(() => {
      if (selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID && shouldRequirePayment) {
        void updateMandatoryFields(paymentsApi, setCashierMandatoryFields, selectedAddressesService?.address?.country);
      }
    }, [selectedAddressesServiceId]);

    return (
      <BillingDataContext.Provider
        value={{
          billingFormData,
          setBillingSameAsShipping,
          billingSameAsShipping,
          isShippingValidForBilling,
        }}>
        <Component {...props} />
      </BillingDataContext.Provider>
    );
  };
}

export function useBillingData() {
  const {billingFormData, setBillingSameAsShipping, billingSameAsShipping, isShippingValidForBilling} =
    useContext(BillingDataContext);
  const {
    paymentStore: {cashierMandatoryFields},
  } = useControllerProps();

  return {
    billingFormData: billingFormData?.billing?.data,

    isFormValid: billingFormData?.isFormValid,
    initForm: billingFormData?.initForm,
    updateContactCountry: billingFormData?.updateContactCountry,
    getBillingFormDataForSubmit: () => billingFormData?.getDataForSubmit(cashierMandatoryFields?.phone),

    contactCountry: billingFormData?.contactCountry,

    setBillingSameAsShipping,
    billingSameAsShipping,

    isShippingValidForBilling,
  };
}

/* eslint-disable sonarjs/cognitive-complexity */
function useBillingFormData(): BillingFormData {
  const {
    checkoutStore: {checkout},
    checkoutSettingsStore: {checkoutSettings, checkoutComposerEnabled},
    paymentStore: {cashierMandatoryFields},
  } = useControllerProps();

  const {experiments} = useExperiments();
  const shouldSplitBillingInfoPrefill = experiments.enabled(SPECS.ShouldSplitBillingInfoPrefill);

  let initialAddress;
  let initialContactDetails;

  if (shouldSplitBillingInfoPrefill) {
    const hasBillingInfoAddress = !isAddressModelEmpty(checkout.billingInfo?.address);
    const hasBillingInfoContact = !isContactModelEmpty(checkout.billingInfo?.contact);

    initialAddress = hasBillingInfoAddress ? checkout.billingInfo?.address : checkout.shippingDestination?.address;
    initialContactDetails = hasBillingInfoContact
      ? checkout.billingInfo?.contact
      : checkout.shippingDestination?.contact;
  } else {
    const hasBillingInfo = Boolean(checkout.billingInfo);

    initialAddress = hasBillingInfo ? checkout.billingInfo?.address : checkout.shippingDestination?.address;
    initialContactDetails = hasBillingInfo ? checkout.billingInfo?.contact : checkout.shippingDestination?.contact;
  }

  const [contactCountry, setContactCountry] = useState(initialAddress?.country);

  const billingForm = useFormInstance(
    getBillingFormInitialState({
      checkoutSettings,
      contact: initialContactDetails,
      country: initialAddress?.country,
      overridePhone: cashierMandatoryFields.phone,
      checkoutComposerEnabled,
      initialAddress,
      billingContact: checkout.billingInfo?.contact,
    })
  );

  const isFormValid = async () => billingForm.isValid();

  const initForm = (billingInfo: AddressWithContactModel | undefined = checkout.billingInfo) => {
    const initialAddress = getAddressDetailsForForm(billingInfo);
    if (initialAddress?.country && shouldSplitBillingInfoPrefill) {
      setContactCountry(initialAddress.country);
    }

    billingForm.data.setFormValues(
      getBillingFormInitialState({
        checkoutSettings,
        contact: getContactDetailsForForm(billingInfo),
        country: initialAddress?.country,
        overridePhone: cashierMandatoryFields.phone,
        checkoutComposerEnabled,
        billingContact: billingInfo?.contact,
        initialAddress,
      })
    );
  };

  const getContactDetailsForForm = (billingInfo: AddressWithContactModel | undefined): ContactModel | undefined => {
    if (billingInfo?.addressesServiceId === ADD_NEW_ADDRESS_ID) {
      return undefined;
    }

    if (shouldSplitBillingInfoPrefill) {
      const hasBillingInfoContact = !isContactModelEmpty(checkout.billingInfo?.contact);
      return hasBillingInfoContact ? billingInfo?.contact : checkout.shippingDestination?.contact;
    } else {
      return billingInfo ? billingInfo?.contact : checkout.shippingDestination?.contact;
    }
  };

  const getAddressDetailsForForm = (billingInfo: AddressWithContactModel | undefined): AddressModel | undefined => {
    if (shouldSplitBillingInfoPrefill) {
      if (billingInfo?.addressesServiceId === ADD_NEW_ADDRESS_ID) {
        return undefined;
      }

      const hasBillingInfoAddress = !isAddressModelEmpty(billingInfo?.address);
      return hasBillingInfoAddress ? billingInfo?.address : checkout.shippingDestination?.address;
    }

    return billingInfo ? billingInfo?.address : checkout.shippingDestination?.address;
  };

  const areFormsRendered = () => billingForm.isRendered();

  const updateContactCountry = (country: string) => {
    setContactCountry(country);
    billingForm.data.setFormValues({
      ...billingForm.data.formValues,
      full_name: {
        ...(billingForm.data.formValues.full_name as FullNameValue),
        country,
      },
    });
  };

  const getDataForSubmit = (
    overridePhone?: boolean
  ): {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
  } => {
    if (!areFormsRendered()) {
      return {};
    }

    const contactDetails = getContactDetailsFromContactFormValues(
      billingForm.data.formValues,
      checkoutSettings,
      checkoutComposerEnabled,
      overridePhone
    );
    const address = getAddressFromAddressFormValues(
      checkoutSettings,
      billingForm.data.formValues,
      checkoutComposerEnabled
    );
    const vatId = getVatFromVatFormValues(billingForm.data.formValues);

    return {
      contactDetails: {
        ...contactDetails,
        ...(vatId ? {vatId} : {}),
      },
      address,
    };
  };

  return {
    billing: billingForm,
    isFormValid,
    initForm,
    updateContactCountry,
    getDataForSubmit,
    contactCountry,
  };
}
