import {Text, TextButton} from 'wix-ui-tpa/cssVars';
import React from 'react';
import {classes} from './SummaryTitle.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {BiMobilePosition} from '../../../../domain/utils/bi.util';

export enum SummaryTitleDataHooks {
  root = 'SummaryTitleDataHooks.root',
  titleText = 'SummaryTitleDataHooks.title',
  editCartButton = 'SummaryTitleDataHooks.editCartButton',
  numberOfOrders = 'SummaryTitleDataHooks.numberOfOrders',
}
export interface SummaryTitleProps {
  shouldShowNumberOfOrders?: boolean;
}
export const SummaryTitle = ({shouldShowNumberOfOrders = true}: SummaryTitleProps) => {
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {editCartUrl, clickOnEditCart, isDonationsTheme},
    checkoutStore: {checkout},
    isOneColumnView,
  } = useControllerProps();

  const handleEditCartClick = () => {
    clickOnEditCart(isOneColumnView ? BiMobilePosition.top : undefined);
  };

  return (
    <div data-hook={SummaryTitleDataHooks.root} className={classes.title}>
      <div className={classes.header}>
        <Text tagName={'h2'} className={classes.titleText} data-hook={SummaryTitleDataHooks.titleText}>
          {isDonationsTheme
            ? localeKeys.checkout.donations.donation_summary()
            : localeKeys.checkout.order_summary.$value()}
        </Text>{' '}
        {shouldShowNumberOfOrders && (
          <Text
            data-hook={SummaryTitleDataHooks.numberOfOrders}
            className={classes.numberOfOrders}
            aria-label={localeKeys.checkout.orderSummary.itemAmount_ariaLabel({
              itemNumber: checkout.numberOfItems,
            })}>
            ({checkout.numberOfItems})
          </Text>
        )}
      </div>
      {checkout.cartId && (
        <div className={classes.editCartButtonWrapper}>
          <TextButton
            as={'a'}
            href={editCartUrl}
            onClick={handleEditCartClick}
            className={classes.editCartButton}
            data-hook={SummaryTitleDataHooks.editCartButton}>
            {localeKeys.checkout.edit_cart()}
          </TextButton>
        </div>
      )}
    </div>
  );
};
