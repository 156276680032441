import React from 'react';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {withSentryErrorBoundary} from '@wix/native-components-infra';
import {ECOM_PLATFORM_CHECKOUT_DSN} from '@wix/wixstores-client-storefront-sdk';
import {Header} from '../Header/Header';
import {Summary} from '../Summary/Summary';
import {PlaceOrderFastFlow} from '../PlaceOrderFastFlow/PlaceOrderFastFlow';
import {Footer} from '../Footer/Footer';
import {useControllerProps} from '../Widget/ControllerContext';
import {Layout} from '../Layout/Layout';
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars';
import {FullFlow} from '../FullFlow/FullFlow';
import {SummaryLocation} from '../../../types/app.types';

export enum CheckoutAppDataHook {
  root = 'CheckoutAppDataHook.root',
  footer = 'CheckoutAppDataHook.footer',
  header = 'CheckoutAppDataHook.header',
  summary = 'CheckoutAppDataHook.summary',
}

const CheckoutApp = () => {
  const {failedToFetch, isLoading, checkoutStore} = useControllerProps();
  const {isRTL} = useEnvironment();

  return (
    <TPAComponentsProvider value={{rtl: isRTL}}>
      <Layout
        dataHook={CheckoutAppDataHook.root}
        isLoading={isLoading}
        failedToFetch={failedToFetch}
        dir={getDirValue(isRTL)}
        Header={() => <Header dataHook={CheckoutAppDataHook.header} />}
        Aside={() => <Summary dataHook={CheckoutAppDataHook.summary} location={SummaryLocation.checkoutApp} />}
        Footer={() => <Footer dataHook={CheckoutAppDataHook.footer} />}>
        {checkoutStore?.isFastFlow ? <PlaceOrderFastFlow /> : <FullFlow />}
      </Layout>
    </TPAComponentsProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(CheckoutApp, {
  dsn: ECOM_PLATFORM_CHECKOUT_DSN,
  config: {environment: 'Native Component'},
});

const getDirValue = (isRTL: boolean): string => {
  /* istanbul ignore next */
  return isRTL ? 'rtl' : 'ltr';
};
