import React from 'react';
import {Image, Text, TextButton, TextButtonPriority, ImageAspectRatioPresets} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {CheckoutSlot} from '../CheckoutSlot';
import {SlotId} from '../../../domain/utils/slotId';

export enum HeaderDataHooks {
  Logo = 'HeaderDataHooks.Logo',
  StoreName = 'HeaderDataHooks.StoreName',
  Title = 'HeaderDataHooks.Title',
  HeaderLink = 'HeaderDataHooks.HeaderLink',
  ContinueShoppingButton = 'HeaderDataHooks.ContinueShoppingButton',
}

export const Header = ({dataHook}: {dataHook: string}) => {
  const {isOneColumnView} = useControllerProps();
  const localKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutSettings},
    navigationStore: {continueShoppingUrl, clickOnContinueShopping, isContinueShoppingEnabled},
  } = useControllerProps();
  const {experiments} = useExperiments();
  const shouldShowCheckoutWordWhenUsingLogo = !experiments.enabled(SPECS.RemoveCheckoutWordWhenUsingLogo);
  const addSlotToCheckout = experiments.enabled(SPECS.AddSlotToCheckout);

  return (
    <header className={classes.header}>
      <div className={st(classes.innerHeader)} data-hook={dataHook}>
        <TextButton
          data-hook={HeaderDataHooks.HeaderLink}
          className={classes.headerTitleAndLogo}
          contentClassName={classes.headerTitleAndLogoContent}
          as={'a'}
          onClick={() => clickOnContinueShopping()}
          href={continueShoppingUrl}>
          {checkoutSettings.logoUrl && (
            <Image
              data-hook={HeaderDataHooks.Logo}
              className={classes.logo}
              src={checkoutSettings.logoUrl}
              aspectRatio={ImageAspectRatioPresets.square}
              fluid={true}
              alt={localKeys.checkout.page.siteLogo_altTextDefault()}
            />
          )}
          {(shouldShowCheckoutWordWhenUsingLogo || !checkoutSettings.logoUrl) && (
            <span>
              {checkoutSettings.storeName && !checkoutSettings.logoUrl && (
                <Text className={classes.headerStoreName} data-hook={HeaderDataHooks.StoreName}>
                  {checkoutSettings.storeName}
                </Text>
              )}
              <Text className={classes.headerTitle} data-hook={HeaderDataHooks.Title}>
                {checkoutSettings.storeName || checkoutSettings.logoUrl
                  ? localKeys.checkout.header.store_title_suffix()
                  : localKeys.checkout.header_default_title()}
              </Text>
            </span>
          )}
        </TextButton>
        {isContinueShoppingEnabled && (
          <TextButton
            as={'a'}
            className={classes.continueShoppingButton}
            data-hook={HeaderDataHooks.ContinueShoppingButton}
            href={continueShoppingUrl}
            onClick={() => clickOnContinueShopping()}
            priority={TextButtonPriority.link}>
            {localKeys.checkout.continue_shopping()}
          </TextButton>
        )}
      </div>
      <div className={classes.divider} />
      {
        /* istanbul ignore next: test slot */ addSlotToCheckout && (
          <CheckoutSlot
            className={isOneColumnView ? classes.headerCheckoutSlotMobile : classes.headerCheckoutSlot}
            slotId={SlotId.HeaderCheckoutSlot}
          />
        )
      }
    </header>
  );
};
