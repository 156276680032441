import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {Violation} from '../../Violations/Violation';
import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {ItemOutOfStock} from './ItemOutOfStock/ItemOutOfStock';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {ViolationModel} from '../../../../domain/models/Violation.model';

export const LineItemAdditionalInfo = ({
  lineItem,
  isItemOutOfStock,
  lineItemViolation,
}: {
  lineItem: LineItemModel;
  isItemOutOfStock: boolean;
  lineItemViolation?: ViolationModel;
}) => {
  const {
    checkoutStore: {removeLineItem},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();

  /* istanbul ignore else */
  if (isItemOutOfStock) {
    return (
      <ItemOutOfStock
        message={localeKeys.checkout.order_summary.item_out_of_stock_error()}
        removeButtonText={localeKeys.checkout.order_summary.removeFromCartError.cta()}
        removeButtonOnClick={() => void removeLineItem(lineItem.id)}
      />
    );
  } else if (lineItemViolation) {
    return <Violation severity={lineItemViolation.severity} description={lineItemViolation.description} />;
  }
  /* istanbul ignore next */
  return null;
};
