import {FIELDS_GAP, FORMS_TEMPLATE_IDS, FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {FormProps} from '../ContactForm/ContactForm';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {useControllerProps} from '../../Widget/ControllerContext';

const EmailFormInternal = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: FormProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutComposerEnabled},
    navigationStore: {isDonationsTheme},
  } = useControllerProps();

  const i18n = useICUI18n();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        email: {
          label: isDonationsTheme
            ? localeKeys.checkout.donations.address_information.donor_email_label()
            : localeKeys.checkout.address_information.email_label(),
        },
      }}
      formId={
        checkoutComposerEnabled
          ? /* istanbul ignore next */ FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EMAIL
          : FORMS_TEMPLATE_IDS.EMAIL
      }
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
      style={{
        styleParams: {
          numbers: {
            rowSpacing: FIELDS_GAP,
          },
        },
      }}
    />
  );
};

export const EmailForm = React.memo(EmailFormInternal);
