import React from 'react';
import {IconToggle, Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './FoldableSummarySection.st.css';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import {isLineItemErrorViolationExist} from '../LineItem/LineItem.utils';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';
import {getTotal} from '../../../../domain/utils/total.util';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';

export enum FoldableSummarySectionDataHook {
  root = 'FoldableSummarySectionDataHooks.root',
  content = 'FoldableSummarySectionDataHook.content',
  total = 'FoldableSummarySectionDataHook.total',
  totalWrapper = 'FoldableSummarySectionDataHook.totalWrapper',
  errorIcon = 'FoldableSummarySectionDataHook.errorIcon',
  chevronUp = 'FoldableSummarySectionDataHook.chevronUp',
  chevronDown = 'FoldableSummarySectionDataHook.chevronDown',
}

export interface FoldableSummarySectionProps {
  onClick: () => void;
  isOpen: boolean;
}

export const FoldableSummarySection = ({onClick, isOpen}: FoldableSummarySectionProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {checkout, shouldShowViolations, hasPartialOutOfStockError},
  } = useControllerProps();
  const shouldShowErrorIcon =
    !!checkout.errors.noItemsError ||
    !!checkout.errors.couponError ||
    !!checkout.errors.giftCardError ||
    (shouldShowViolations && isLineItemErrorViolationExist(checkout.violations)) ||
    hasPartialOutOfStockError;
  const totalsSectionId = String(Math.random());
  return (
    <button
      type="button"
      data-hook={FoldableSummarySectionDataHook.root}
      className={classes.root}
      onClick={onClick}
      aria-expanded={isOpen}
      aria-label={shouldShowErrorIcon ? localeKeys.checkout.mobile.alertIcon.ariaLabel() : undefined}
      aria-describedby={totalsSectionId}>
      <div className={classes.contentWrapper}>
        {shouldShowErrorIcon && (
          <ErrorIcon data-hook={FoldableSummarySectionDataHook.errorIcon} className={classes.errorIcon} />
        )}
        <Text data-hook={FoldableSummarySectionDataHook.content}>
          {isOpen
            ? localeKeys.checkout.mobile.hideOrderSummary.label()
            : localeKeys.checkout.mobile.showOrderSummary.label()}
        </Text>
      </div>
      <div
        data-hook={FoldableSummarySectionDataHook.totalWrapper}
        className={classes.totalWrapper}
        aria-hidden={true}
        id={totalsSectionId}>
        <Text data-hook={FoldableSummarySectionDataHook.total} className={classes.total}>
          {getTotal(checkout)}
        </Text>
        <IconToggle
          icon={<ChevronDown data-hook={FoldableSummarySectionDataHook.chevronDown} />}
          checkedIcon={<ChevronUp data-hook={FoldableSummarySectionDataHook.chevronUp} />}
          checked={isOpen}
        />
      </div>
    </button>
  );
};
