import React from 'react';
import {PlaceOrderDataHook} from '../PlaceOrderStep';
import {
  BottomMobileSummary,
  BottomMobileSummaryDataHooks,
} from '../../../../Summary/BottomMobileSummary/BottomMobileSummary';
import {useControllerProps} from '../../../../Widget/ControllerContext';

export const PlaceOrderEmpty = () => {
  const {isOneColumnView} = useControllerProps();

  return (
    <div data-hook={PlaceOrderDataHook.empty}>
      {isOneColumnView && <BottomMobileSummary dataHook={BottomMobileSummaryDataHooks.placeOrderEmpty} />}
    </div>
  );
};
