import {useEffect, useState} from 'react';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {useMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';
import {ADD_NEW_ADDRESS_ID, SPECS} from '../../../constants';
import {StepId} from '../../../../../types/app.types';
import {useBillingData} from '../PaymentStep/WithBillingData/WithBillingData';
import {useExperiments} from '@wix/yoshi-flow-editor';

export function UsePlaceOrderButtonState() {
  const {
    checkoutStore: {updateCheckoutError, isPlaceOrderButtonDisabled, placeOrderError, isFastFlow},
    formsStore: {areFormsLoaded},
    stepsManagerStore: {activeStep},
    memberStore: {isMember},
    paymentStore: {shouldRequirePayment},
  } = useControllerProps();
  const {experiments} = useExperiments();

  const {editMode, selectedAddressesServiceId, areMemberDetailsValid} = useMemberDetailsData();
  const {isShippingValidForBilling, billingSameAsShipping} = useBillingData();

  const [placeOrderButtonState, setPlaceOrderButtonState] = useState(StatesButtonStates.IDLE);

  const [shouldPlaceOrderButtonBeDisabled, setShouldPlaceOrderButtonBeDisabled] = useState(
    (!isFastFlow && activeStep.stepId === StepId.paymentAndPlaceOrder && shouldRequirePayment && !areFormsLoaded) ||
      isPlaceOrderButtonDisabled
  );

  useEffect(() => {
    if (isFastFlow) {
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    }
  }, [isPlaceOrderButtonDisabled, isFastFlow]);

  useEffect(() => {
    if (updateCheckoutError || placeOrderError) {
      setPlaceOrderButtonState(StatesButtonStates.IDLE);
      setShouldPlaceOrderButtonBeDisabled(isPlaceOrderButtonDisabled);
    }
  }, [updateCheckoutError, placeOrderError, isPlaceOrderButtonDisabled]);

  useEffect(() => {
    if (!isFastFlow && activeStep.stepId === StepId.paymentAndPlaceOrder && shouldRequirePayment && isMember) {
      /* istanbul ignore next */
      const areMemberDetailsValidEvenInCaseOfAddressDeleted =
        isShippingValidForBilling &&
        billingSameAsShipping &&
        !areMemberDetailsValid &&
        experiments.enabled(SPECS.ShouldBeResilientAgainstAddressesFailures)
          ? true
          : areMemberDetailsValid;
      const disablePlaceOrderButton =
        !areFormsLoaded ||
        (!areMemberDetailsValidEvenInCaseOfAddressDeleted &&
          !editMode &&
          selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID) ||
        editMode ||
        selectedAddressesServiceId === ADD_NEW_ADDRESS_ID;

      if (placeOrderButtonState !== StatesButtonStates.IN_PROGRESS) {
        setShouldPlaceOrderButtonBeDisabled(disablePlaceOrderButton || isPlaceOrderButtonDisabled);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areFormsLoaded,
    areMemberDetailsValid,
    editMode,
    selectedAddressesServiceId,
    activeStep,
    isFastFlow,
    isPlaceOrderButtonDisabled,
    shouldRequirePayment,
    placeOrderButtonState,
    isMember,
    isShippingValidForBilling,
    billingSameAsShipping,
  ]);

  return {
    placeOrderButtonState,
    shouldPlaceOrderButtonBeDisabled,
    setPlaceOrderButtonState,
    setShouldPlaceOrderButtonBeDisabled,
  };
}
