import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {Checkboxes} from '../../../../../Checkboxes/Checkboxes';
import {classes} from './PlaceOrder.st.css';
import {useLocaleKeys} from '../../../../../../../locale-keys/LocaleKeys';
import {ViolationsAndPlaceOrderButton} from '../../../../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../../../../../types/app.types';
import {TextPriority} from 'wix-ui-tpa';
import {useControllerProps} from '../../../../../Widget/ControllerContext';

export enum PlaceOrderSectionDataHook {
  root = 'PlaceOrderSectionDataHook.root',
  title = 'PlaceOrderSectionDataHook.title',
  subtitle = 'PlaceOrderSectionDataHook.subtitle',
  violations = 'PlaceOrderSectionDataHook.violations',
}

export const PlaceOrder = ({checkboxesRef}: {checkboxesRef?: React.RefObject<HTMLDivElement>}) => {
  const localeKeys = useLocaleKeys();
  const {
    navigationStore: {isDonationsTheme},
  } = useControllerProps();
  return (
    <div className={classes.root} data-hook={PlaceOrderSectionDataHook.root}>
      <div className={classes.title}>
        <Text
          data-hook={PlaceOrderSectionDataHook.title}
          tagName={'h2'}
          priority={TextPriority.primary}
          className={classes.titleText}>
          {isDonationsTheme
            ? localeKeys.checkout.donations.review_donation.title()
            : localeKeys.checkout.place_order.$value()}
        </Text>
      </div>
      <div className={classes.subtitle}>
        <Text data-hook={PlaceOrderSectionDataHook.subtitle}>
          {isDonationsTheme
            ? localeKeys.checkout.donations.review_donation.description()
            : localeKeys.checkout.place_order.description()}
        </Text>
      </div>
      <Checkboxes checkboxesRef={checkboxesRef} />
      <ViolationsAndPlaceOrderButton
        location={ViolationsAndPlaceOrderButtonLocation.paymentAndPlaceOrderStep}
        checkboxesRef={checkboxesRef}
      />
    </div>
  );
};
